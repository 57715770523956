<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-row>
        <v-col
          md="4"
          cols="12"
          class="text-center"
        >
          <v-avatar
            rounded
            size="120"
            class="me-6"
          >
            <v-img :src="get_image"></v-img>
          </v-avatar>
        </v-col>

        <!-- upload photo -->
        <v-col
            md="8"
            cols="12"
          >
            <v-file-input
              show-size
              v-model="user_pic"
              ref="user_pic"
              truncate-length="18"
              style="cursor: pointer"
              label="Choose Your User Picture"
              accept=".jpeg,.png,.jpg,GIF"
            ></v-file-input>

            <p class="text-sm mt-5">
              {{ inputFileName }}
            <spinner v-if="loading"></spinner>
            <v-btn v-else
              color="primary"
              small
              class="me-3 mt-4 ml-2"
              @click="updateAccountPic()"
            >
              Update Picture
            </v-btn>
          </p>
        </v-col>

      </v-row>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="fullnames"
              ref="fullnames"
              label="Full Names"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="username"
              ref="username"
              label="Username"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="phone_number"
              ref="phone_number"
              label="Phone Number (+237)"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="email"
              ref="email"
              dense
              label="Email"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <!-- <v-col cols="12">
            <v-textarea
              v-model="description"
              outlined
              rows="3"
              ref="description" id="description"
              label="A brief description of your life so far"
            ></v-textarea>
          </v-col> -->

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="serial_no"
              outlined disabled
              dense
              id="serial_no" ref="serial_no"
              label="Serial No"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="birthday"
              ref="birthday"
              type="date"
              outlined
              dense
              label="Birthday"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="profession"
              ref="profession"
              outlined
              dense
              label="Your Profession"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="residence"
              outlined
              dense
              id="residence" ref="residence"
              label="Residential Address"
              :rules="[v => !!v || 'Your Residential Address is required']"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="gender"
              ref="gender"
              outlined
              dense
              label="Gender"
              :items="genderItems"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="language"
              ref="language"
              outlined
              dense
              label="Language"
              :items="['English','Spanish','French','German']"
            ></v-select>
          </v-col>

          <v-col cols="12">
            <spinner v-if="loading"></spinner>
            <v-btn v-else
              color="primary"
              class="me-3 mt-4"
              @click="updateAccountDetails()"
            >
              Save changes
            </v-btn>
          </v-col>

        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'

export default {
  components: {
    Spinner,
  },
  
  data() {
    const status = ['Active', 'Inactive', 'Pending', 'Closed']

    return {
      status,
      loading: false,
      inputFileName: "Allowed JPG or PNG. Max size of 5Mbytes",
      user_pic: [],
      username: "",
      fullnames: "",
      phone_number: "",
      email: "",
      get_image: "",

      genderItems: [
        'Male',
        'Female',
      ],
      description: "",
      file: "",
      gender: "",
      language: "",
      profession: "",
      birthday: "",
      residence: "",    
      serial_no: "",    

      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },

  methods:{
      async updateAccountDetails(){
          if(this.username.length < 4){
              this.$store.commit('setSnackBarMessage', "User name is too short, user name needs to be at least 4 characters")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.username.focus()  // this causes a an auto fucos to the element
          }
          else if(this.fullnames === ""){
              this.$store.commit('setSnackBarMessage', "Please input full names")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.fullnames.focus()  // this causes a an auto fucos to the element
          }
          else if(this.phone_number === ""){
              this.$store.commit('setSnackBarMessage', "Please input phone number")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.phone_number.focus()  // this causes a an auto fucos to the element
          }
          else if(this.email === ""){
              this.$store.commit('setSnackBarMessage', "Please input email")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.email.focus()  // this causes a an auto fucos to the element
          }
          else {
              this.loading = true
              console.log(this.user_pic)
              const user_id = this.$route.params.client_id

              let formData = new FormData()
              formData.append('username', this.username)
              formData.append('phone_number', this.phone_number)
              formData.append('fullnames', this.fullnames)
              formData.append('email', this.email)

              formData.append('gender', this.gender)
              formData.append('birthday', this.birthday)
              formData.append('language', this.language)
              formData.append('profession', this.profession)
              formData.append('residence', this.residence)
              formData.append('description', this.description)

              await axios
                  .post('/api/v1/manager/update_account_details/account/'+user_id+'/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                  .then(response => {
                      this.$store.commit('setSnackBarMessage', response.data[0].text)
                      this.$store.commit('setSnackBarColor', response.data[0].color)
                      this.$store.commit('activateSnackBar', true)
                      this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                      if (response.data[0].category === "success"){
                        this.$router.go()
                      }
                  })
                  .catch(error => {
                      if (error.response){
                          for (const property in error.response.data){
                              this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                              this.$store.commit('setSnackBarColor', "red darken-4")
                              this.$store.commit('activateSnackBar', true)
                          }
                      }else{
                          this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                          this.$store.commit('setSnackBarColor', "red darken-4")
                          this.$store.commit('activateSnackBar', true)
                      }
                  })
  
              this.loading = false
          }
      },

      async updateAccountPic(){
          if(this.user_pic.size > 6000000){
              this.$store.commit('setSnackBarMessage', "Please upload a picture less than 5mb")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.user_pic.focus()  // this causes a an auto fucos to the element
          }
          else {
              this.loading = true
              let formData = new FormData()
              formData.append('user_pic', this.user_pic)
              const user_id = this.$route.params.client_id

              await axios
                  .post('/api/v1/manager/update_account_details/user_pic/'+user_id+'/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                  .then(response => {
                      this.$store.commit('setSnackBarMessage', response.data[0].text)
                      this.$store.commit('setSnackBarColor', response.data[0].color)
                      this.$store.commit('activateSnackBar', true)
                      this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                      if (response.data[0].category === "success"){
                        this.$router.go()
                      }
                  })
                  .catch(error => {
                      if (error.response){
                          for (const property in error.response.data){
                              this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                              this.$store.commit('setSnackBarColor', "red darken-4")
                              this.$store.commit('activateSnackBar', true)
                          }
                      }else{
                          this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                          this.$store.commit('setSnackBarColor', "red darken-4")
                          this.$store.commit('activateSnackBar', true)
                      }
                  })

              this.loading = false
          }
      },

      getUserInfo() {
        const user_id = this.$route.params.client_id
        axios
            .get('/api/v1/manager/get/user/by_id/'+user_id+'/')
            .then(response => {
              console.log(response.data)

              this.username = response.data[0]["user"]["username"]
              this.fullnames = response.data[0]["user"]["first_name"]
              this.phone_number = response.data[0]["user_profile"]["phone_number"]
              this.email = response.data[0]["user"]["email"]
              this.get_image = response.data[0]["user_profile"]["get_image"]

              this.gender = response.data[0]["user_profile"]["gender"]
              this.birthday = response.data[0]["user_profile"]["date_of_birth"]
              this.language = response.data[0]["user_profile"]["language"]
              this.profession = response.data[0]["user_profile"]["profession"]
              this.residence = response.data[0]["user_profile"]["address"]
              this.serial_no = response.data[0]["user_profile"]["serial_no"]
              // this.description = response.data[0]["user_worker_info"]["personal_info"]
            })
            .catch(error => {
                console.log(error)
                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            })
      },
  },

  mounted(){
    this.getUserInfo()
  },
}
</script>
