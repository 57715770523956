var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-3 mt-2",attrs:{"flat":""}},[_c('v-card-text',[_c('v-card-title',{staticClass:"mt-0 pt-0"},[_c('span',{staticClass:"me-3"},[_vm._v("("+_vm._s(_vm.clientCampaigns.length)+") Campaigns")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('h3',{staticClass:"ml-3"},[_vm._v("Total Cash Saved: "+_vm._s(_vm.savings_total)+" F CFA ")]),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.clientCampaigns,"item-key":"id","items-per-page":10,"disable-sort":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/savings/clients/campaign/'+item.id}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"cursor":"pointer"}},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.name))]),(item.target_date && item.target_amount)?_c('small',[_vm._v("Type: "),_c('b',[_vm._v("Date & Amount")]),_vm._v(" "),_c('b',[_vm._v("Date")]),_vm._v(" at "+_vm._s(item.percentage_completed)+" Completion")]):(item.target_amount)?_c('small',[_vm._v("Type: "),_c('b',[_vm._v("Amount")]),_vm._v(" at "+_vm._s(item.percentage_completed)+" Completion")]):(item.target_date)?_c('small',[_vm._v("Type: "),_c('b',[_vm._v("Date")]),_vm._v(" at "+_vm._s(item.percentage_completed)+" Completion")]):_c('small',[_vm._v("Type: "),_c('b',[_vm._v("Undefined")]),_vm._v(" at --- Completion")])])])]}},{key:"item.saved_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.saved_amount)+" F CFA ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[item.status]}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.get_date_str",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.get_date_str)+" ")]}}],null,true)})],1),_c('v-card-text',[_c('v-card-title',{staticClass:"mt-0 pt-0"},[_c('span',{staticClass:"me-3"},[_vm._v("("+_vm._s(_vm.clientLoans.length)+") Loans")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('h3',{staticClass:"ml-3"},[_vm._v("Total Cash Owing: "+_vm._s(_vm.owing_total)+" F CFA ")]),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.clientLoans,"item-key":"id","items-per-page":10,"disable-sort":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/savings/clients/loan/'+item.id}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"cursor":"pointer"}},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.name))]),(item.target_date && item.target_amount)?_c('small',[_vm._v("Type: "),_c('b',[_vm._v("Date & Amount")]),_vm._v(" "),_c('b',[_vm._v("Date")]),_vm._v(" at "+_vm._s(item.percentage_completed)+" Completion")]):(item.target_amount)?_c('small',[_vm._v("Type: "),_c('b',[_vm._v("Amount")]),_vm._v(" at "+_vm._s(item.percentage_completed)+" Completion")]):(item.target_date)?_c('small',[_vm._v("Type: "),_c('b',[_vm._v("Date")]),_vm._v(" at "+_vm._s(item.percentage_completed)+" Completion")]):_c('small',[_vm._v("Type: "),_c('b',[_vm._v("Undefined")]),_vm._v(" at --- Completion")])])])]}},{key:"item.saved_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.saved_amount)+" F CFA ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[item.status]}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.get_date_str",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.get_date_str)+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }