<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form>
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <!-- current password -->
              <v-text-field
                v-model="managerPassword"
                :type="isManagerPasswordVisible ? 'text' : 'password'"
                :append-icon="isManagerPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Your Manager Password"
                outlined
                dense ref="managerPassword"
                @click:append="isManagerPasswordVisible = !isManagerPasswordVisible"
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Client New Password"
                outlined
                dense ref="newPassword"
                hint="Make sure it's at least 8 characters."
                persistent-hint
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="newPasswordAgain"
                :type="isnewPasswordAgainVisible ? 'text' : 'password'"
                :append-icon="isnewPasswordAgainVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Confirm New Password"
                outlined
                dense ref="newPasswordAgain"
                class="mt-3"
                @click:append="isnewPasswordAgainVisible = !isnewPasswordAgainVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->
      <v-divider></v-divider>

      <div class="pa-3">

        <!-- action buttons -->
        <v-card-text>
          <spinner v-if="loading"></spinner>
          <v-btn v-else
            color="primary"
            class="me-3 mt-3"
            @click="updateAccountDetails()"
          >
            Change Password
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'

export default {
  components: {
    Spinner,
  },
  data() {

    return {
      isManagerPasswordVisible: '',
      isNewPasswordVisible: '',
      managerPassword: '',
      isnewPasswordAgainVisible: '',
      newPassword: '',
      newPasswordAgain: '',
      loading: false,

      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }

  },

  methods:{
      async updateAccountDetails(){
          console.log('updateAccountDetails')
          if(this.managerPassword.length < 8){
              this.$store.commit('setSnackBarMessage', "Password is too short, password needs to be at least 8 characters")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.password.focus()  // this causes a an auto fucos to the element
          }
          else if(this.newPassword.length < 8){
              this.$store.commit('setSnackBarMessage', "New password is too short, needs to be at least 8 characters")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.newPassword.focus()  // this causes a an auto fucos to the element
          }
          else if(this.newPasswordAgain.length < 8){
              this.$store.commit('setSnackBarMessage', "New password again is too short, needs to be at least 8 characters")
              this.$store.commit('setSnackBarColor', "red darken-4")
              this.$store.commit('activateSnackBar', true)
              this.$refs.newPasswordAgain.focus()  // this causes a an auto fucos to the element
          }
          else {
              this.loading = true
              const user_id = this.$route.params.client_id
              const formData = {
                managerPassword: this.managerPassword,
                newPassword: this.newPassword,
                newPasswordAgain: this.newPasswordAgain,
              }

              await axios
                  .post('/api/v1/manager/update_account_details/password/'+user_id+'/', formData)
                  .then(response => {
                    this.$store.commit('setSnackBarMessage', response.data[0].text)
                    this.$store.commit('setSnackBarColor', response.data[0].color)
                    this.$store.commit('activateSnackBar', true)
                    this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                    if (response.data[0].category === "success"){
                      this.$router.go()
                    }
                  })
                  .catch(error => {
                      if (error.response){
                          for (const property in error.response.data){
                              this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                              this.$store.commit('setSnackBarColor', "red darken-4")
                              this.$store.commit('activateSnackBar', true)
                          }
                      }else{
                          this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                          this.$store.commit('setSnackBarColor', "red darken-4")
                          this.$store.commit('activateSnackBar', true)
                      }
                  })
              this.loading = false
          }

      },
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
