<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
  <v-card-text>
      <v-card-title class="mt-0 pt-0">
        <span class="me-3">({{ clientCampaigns.length }}) Campaigns</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <h3 class="ml-3">Total Cash Saved: {{ savings_total }} F CFA  </h3>
      <v-data-table
        :headers="headers"
        :items="clientCampaigns"
        item-key="id"
        class="table-rounded"
        :items-per-page="10"
        disable-sort
        :search="search"
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <router-link :to="'/savings/clients/campaign/'+item.id" >
            <div class="d-flex flex-column" style="cursor: pointer">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
              <small v-if="item.target_date && item.target_amount">Type: <b>Date & Amount</b> <b>Date</b> at {{ item.percentage_completed }} Completion</small>
              <small v-else-if="item.target_amount">Type: <b>Amount</b> at {{ item.percentage_completed }} Completion</small>
              <small v-else-if="item.target_date">Type: <b>Date</b> at {{ item.percentage_completed }} Completion</small>
              <small v-else>Type: <b>Undefined</b>  at --- Completion</small>
            </div>
          </router-link>
        </template>
        <template #[`item.saved_amount`]="{item}">
          {{ item.saved_amount }} F CFA
        </template>
        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="statusColor[item.status]"
            class="font-weight-medium"
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template #[`item.get_date_str`]="{item}">
          {{ item.get_date_str }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text>
      <v-card-title class="mt-0 pt-0">
        <span class="me-3">({{ clientLoans.length }}) Loans</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <h3 class="ml-3">Total Cash Owing: {{ owing_total }} F CFA  </h3>
      <v-data-table
        :headers="headers"
        :items="clientLoans"
        item-key="id"
        class="table-rounded"
        :items-per-page="10"
        disable-sort
        :search="search"
      >
        <!-- name -->
        <template #[`item.name`]="{item}">
          <router-link :to="'/savings/clients/loan/'+item.id" >
            <div class="d-flex flex-column" style="cursor: pointer">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
              <small v-if="item.target_date && item.target_amount">Type: <b>Date & Amount</b> <b>Date</b> at {{ item.percentage_completed }} Completion</small>
              <small v-else-if="item.target_amount">Type: <b>Amount</b> at {{ item.percentage_completed }} Completion</small>
              <small v-else-if="item.target_date">Type: <b>Date</b> at {{ item.percentage_completed }} Completion</small>
              <small v-else>Type: <b>Undefined</b>  at --- Completion</small>
            </div>
          </router-link>
        </template>
        <template #[`item.saved_amount`]="{item}">
          {{ item.saved_amount }} F CFA
        </template>
        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="statusColor[item.status]"
            class="font-weight-medium"
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template #[`item.get_date_str`]="{item}">
          {{ item.get_date_str }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Spinner from "@/components/Spinner.vue";
import axios from 'axios'

export default {
  components: {
    Spinner,
  },

  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Ongoing: 'primary',
      Completed: 'success',
      Interrupted: 'error',
      Inactivated: 'error',
      /* eslint-enable key-spacing */
    }
    const client_id = this.$route.params.client_id
    return {
      client_id,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Amount Saved', value: 'saved_amount' },
        { text: 'Status', value: 'status' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      statusColor,
      search: '',
      loading: false,
      savings_total: '',
      owing_total: '',
      clientCampaigns: [],
      clientLoans: [],
    }
  },

  methods:{
    async getClientCampaigns(){
      this.loading = true
      const user_id = this.$route.params.client_id

      await axios
        .get('/api/v1/manager/get/client/campaigns/'+user_id+'/')
        .then(response => {
          console.log(response.data)
          this.clientCampaigns = response.data['user_campaigns']
          this.savings_total = response.data['total_saved']
        })
        .catch(error => {
              if (error.response){
                  for (const property in error.response.data){
                      this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                      this.$store.commit('setSnackBarColor', "red darken-4")
                      this.$store.commit('activateSnackBar', true)
                  }
              }else{
                  this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                  this.$store.commit('setSnackBarColor', "red darken-4")
                  this.$store.commit('activateSnackBar', true)
              }
          })
      this.loading = false
    },
    async getClientLoans(){
      this.loading = true
      const user_id = this.$route.params.client_id

      await axios
        .get('/api/v1/manager/get/client/loans/'+user_id+'/')
        .then(response => {
          console.log(response.data)
          this.clientLoans = response.data['user_loans']
          this.owing_total = response.data['total_owing']
        })
        .catch(error => {
            if (error.response){
                for (const property in error.response.data){
                    this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
            }else{
                this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
            }
        })
      this.loading = false
    },
  },

  mounted(){
      this.getClientCampaigns() 
  },

}
</script>
